import { includes, endsWith, isEmpty } from "lodash";
import Vue from "vue";

export function resourceHandler(resource) {
  let { cpuOversell, memOversell } = resource;

  if (resource.limits.cpu) {
    Vue.set(resource.requests, "cpu", (parseFloat(resource.limits.cpu) * cpuOversell) / 100);

    if (resource.limits.cpuUnit === "m") {
      resource.limits.cpu += "m";
      resource.requests.cpu += "m";
    }
  } else {
    delete resource.limits.cpu;
    delete resource.requests.cpu;
  }

  if (resource.limits.memory) {
    Vue.set(resource.requests, "memory", (parseFloat(resource.limits.memory) * memOversell) / 100);
    resource.limits.memory += resource.limits.memoryUnit;
    resource.requests.memory += resource.limits.memoryUnit;
  } else {
    delete resource.limits.memory;
    delete resource.requests.memory;
  }

  delete resource.limits.memoryUnit;
  delete resource.limits.cpuUnit;
}

export function initResource(resource) {
  if (resource.limits) {
    if (resource.limits.cpu) {
      if (includes(resource.limits.cpu, "m")) {
        resource.limits.cpu = resource.limits.cpu.replace("m", "");
        Vue.set(resource.limits, "cpuUnit", "m");
      } else {
        Vue.set(resource.limits, "cpuUnit", "Core");
      }
    } else {
      Vue.set(resource.limits, "cpu", "");
      Vue.set(resource.limits, "cpuUnit", "Core");
    }

    if (resource.limits.memory) {
      let memoryUnit = ["K", "M", "G"];

      for (let unit of memoryUnit) {
        if (includes(resource.limits.memory, unit)) {
          resource.limits.memory = resource.limits.memory.replace(unit, "");
          Vue.set(resource.limits, "memoryUnit", unit);
        }
      }
    } else {
      Vue.set(resource.limits, "memory", "");
      Vue.set(resource.limits, "memoryUnit", "G");
    }

    if (resource.requests) {
      if (resource.requests.cpu) {
        let limitsCpu;
        let requestCpu;

        resource.limits.cpuUnit === "Core"
          ? (limitsCpu = parseFloat(resource.limits.cpu) * 1000)
          : (limitsCpu = parseFloat(resource.limits.cpu));

        includes(resource.requests.cpu, "m")
          ? (requestCpu = parseFloat(resource.requests.cpu))
          : (requestCpu = parseFloat(resource.requests.cpu) * 1000);

        Vue.set(resource, "cpuOversell", parseFloat(((requestCpu / limitsCpu) * 100).toFixed(0)));
      } else {
        Vue.set(resource, "cpuOversell", 1);
      }

      if (resource.requests.memory) {
        let limitsMem;
        let requestMem;

        switch (resource.limits.memoryUnit) {
          case "G":
            limitsMem = parseFloat(resource.limits.memory) * 1024 * 1024;
            break;
          case "M":
            limitsMem = parseFloat(resource.limits.memory) * 1024;
            break;

          case "K":
            limitsMem = parseFloat(resource.limits.memory);
            break;
        }

        if (endsWith(resource.requests.memory, "G")) {
          requestMem = parseFloat(resource.requests.memory) * 1024 * 1024;
        } else if (endsWith(resource.requests.memory, "M")) {
          requestMem = parseFloat(resource.requests.memory) * 1024;
        } else if (endsWith(resource.requests.memory, "K")) {
          requestMem = parseFloat(resource.requests.memory);
        }

        Vue.set(resource, "memOversell", parseFloat(((requestMem / limitsMem) * 100).toFixed(0)));
      } else {
        Vue.set(resource, "memOversell", 1);
      }
    } else {
      Vue.set(resource, "cpuOversell", 100);
      Vue.set(resource, "memOversell", 100);

      Vue.set(resource, "requests", {
        cpu: "",
        memory: ""
      });
    }
  } else {
    Vue.set(resource, "limits", {
      cpu: "",
      cpuUnit: "Core",
      memory: "",
      memoryUnit: "G"
    });

    Vue.set(resource, "requests", {
      cpu: "",
      memory: ""
    });

    Vue.set(resource, "cpuOversell", 1);
    Vue.set(resource, "memOversell", 1);
  }
}

export function getAffinity(form) {
  let nodeAffinity = {
    requiredDuringSchedulingIgnoredDuringExecution: {
      nodeSelectorTerms: [
        {
          matchExpressions: []
        }
      ]
    }
  };

  let podAffinity = {
    requiredDuringSchedulingIgnoredDuringExecution: [
      {
        labelSelector: {
          matchExpressions: []
        },
        namespaces: [],
        topologyKey: ""
      }
    ]
  };

  let podAntiAffinity = {
    requiredDuringSchedulingIgnoredDuringExecution: [
      {
        labelSelector: {
          matchExpressions: []
        },
        namespaces: [],
        topologyKey: ""
      }
    ]
  };

  let affinity = {
    nodeAffinity,
    podAffinity,
    podAntiAffinity
  };

  if (form.spec.type == "Deployment") {
    if (!form.spec.deployment.spec.template.spec.affinity) {
      Vue.set(form.spec.deployment.spec.template.spec, "affinity", affinity);
    } else {
      if (!form.spec.deployment.spec.template.spec.affinity.nodeAffinity) {
        Vue.set(form.spec.deployment.spec.template.spec.affinity, "nodeAffinity", nodeAffinity);
      }

      if (!form.spec.deployment.spec.template.spec.affinity.podAffinity) {
        Vue.set(form.spec.deployment.spec.template.spec.affinity, "podAffinity", podAffinity);
      }

      if (!form.spec.deployment.spec.template.spec.affinity.podAntiAffinity) {
        Vue.set(form.spec.deployment.spec.template.spec.affinity, "podAntiAffinity", podAntiAffinity);
      }
    }

    return form.spec.deployment.spec.template.spec.affinity;
  }

  if (form.spec.type == "StatefulSet") {
    if (!form.spec.statefulset.spec.template.spec.affinity) {
      Vue.set(form.spec.statefulset.spec.template.spec, "affinity", affinity);
    } else {
      if (!form.spec.statefulset.spec.template.spec.affinity.nodeAffinity) {
        Vue.set(form.spec.statefulset.spec.template.spec.affinity, "nodeAffinity", nodeAffinity);
      }

      if (!form.spec.statefulset.spec.template.spec.affinity.podAffinity) {
        Vue.set(form.spec.statefulset.spec.template.spec.affinity, "podAffinity", podAffinity);
      }

      if (!form.spec.statefulset.spec.template.spec.affinity.podAntiAffinity) {
        Vue.set(form.spec.statefulset.spec.template.spec.affinity, "podAntiAffinity", podAntiAffinity);
      }
    }

    return form.spec.statefulset.spec.template.spec.affinity;
  }

  if (form.spec.type == "CronJob") {
    if (!form.spec.cronjob.spec.jobTemplate.spec.template.spec.affinity) {
      Vue.set(form.spec.cronjob.spec.jobTemplate.spec.template.spec, "affinity", affinity);
    } else {
      if (!form.spec.cronjob.spec.jobTemplate.spec.template.spec.affinity.nodeAffinity) {
        Vue.set(form.spec.cronjob.spec.jobTemplate.spec.template.spec.affinity, "nodeAffinity", nodeAffinity);
      }

      if (!form.spec.cronjob.spec.jobTemplate.spec.template.spec.affinity.podAffinity) {
        Vue.set(form.spec.cronjob.spec.jobTemplate.spec.template.spec.affinity, "podAffinity", podAffinity);
      }

      if (!form.spec.cronjob.spec.jobTemplate.spec.template.spec.affinity.podAntiAffinity) {
        Vue.set(form.spec.cronjob.spec.jobTemplate.spec.template.spec.affinity, "podAntiAffinity", podAntiAffinity);
      }
    }

    return form.spec.cronjob.spec.jobTemplate.spec.template.spec.affinity;
  }

  if (form.spec.type == "DaemonSet") {
    if (!form.spec.daemonset.spec.template.spec.affinity) {
      Vue.set(form.spec.daemonset.spec.template.spec, "affinity", affinity);
    } else {
      if (!form.spec.daemonset.spec.template.spec.affinity.nodeAffinity) {
        Vue.set(form.spec.daemonset.spec.template.spec.affinity, "nodeAffinity", nodeAffinity);
      }

      if (!form.spec.daemonset.spec.template.spec.affinity.podAffinity) {
        Vue.set(form.spec.daemonset.spec.template.spec.affinity, "podAffinity", podAffinity);
      }

      if (!form.spec.daemonset.spec.template.spec.affinity.podAntiAffinity) {
        Vue.set(form.spec.daemonset.spec.template.spec.affinity, "podAntiAffinity", podAntiAffinity);
      }
    }

    return form.spec.daemonset.spec.template.spec.affinity;
  }
}

export function affinityHandler(form) {
  let affinity = getAffinity(form);

  if (affinity) {
    if (
      affinity.nodeAffinity.requiredDuringSchedulingIgnoredDuringExecution.nodeSelectorTerms[0].matchExpressions
        .length == 0 &&
      !("preferredDuringSchedulingIgnoredDuringExecution" in affinity.nodeAffinity)
    ) {
      delete affinity.nodeAffinity.requiredDuringSchedulingIgnoredDuringExecution;
    }

    if (
      "podAffinity" in affinity &&
      affinity.podAffinity.requiredDuringSchedulingIgnoredDuringExecution[0].labelSelector.matchExpressions.length == 0
    ) {
      delete affinity.podAffinity.requiredDuringSchedulingIgnoredDuringExecution;
    }

    if (
      "podAntiAffinity" in affinity &&
      affinity.podAntiAffinity.requiredDuringSchedulingIgnoredDuringExecution[0].labelSelector.matchExpressions
        .length == 0
    ) {
      delete affinity.podAntiAffinity.requiredDuringSchedulingIgnoredDuringExecution;
    }

    if (isEmpty(affinity.nodeAffinity) && isEmpty(affinity.podAffinity) && isEmpty(affinity.podAntiAffinity)) {
      if (form.spec.type == "Deployment") {
        delete form.spec.deployment.spec.template.spec.affinity;
      }

      if (form.spec.type == "StatefulSet") {
        delete form.spec.statefulset.spec.template.spec.affinity;
      }

      if (form.spec.type == "CronJob") {
        delete form.spec.cronjob.spec.jobTemplate.spec.template.spec.affinity;
      }

      if (form.spec.type == "DaemonSet") {
        delete form.spec.daemonset.spec.template.spec.affinity;
      }
    } else {
      if (isEmpty(affinity.nodeAffinity)) delete affinity.nodeAffinity;
      if (isEmpty(affinity.podAffinity)) delete affinity.podAffinity;
      if (isEmpty(affinity.podAntiAffinity)) delete affinity.podAntiAffinity;
    }
  }
}

export function getImagePullSecrets(form) {
  if (form.spec.type == "Deployment") {
    if (!form.spec.deployment.spec.template.spec.imagePullSecrets)
      Vue.set(form.spec.deployment.spec.template.spec, "imagePullSecrets", []);

    return form.spec.deployment.spec.template.spec.imagePullSecrets;
  }

  if (form.spec.type == "StatefulSet") {
    if (!form.spec.statefulset.spec.template.spec.imagePullSecrets)
      Vue.set(form.spec.statefulset.spec.template.spec, "imagePullSecrets", []);

    return form.spec.statefulset.spec.template.spec.imagePullSecrets;
  }

  if (form.spec.type == "CronJob") {
    if (!form.spec.cronjob.spec.jobTemplate.spec.template.spec.imagePullSecrets)
      Vue.set(form.spec.cronjob.spec.jobTemplate.spec.template.spec, "imagePullSecrets", []);
    return form.spec.cronjob.spec.jobTemplate.spec.template.spec.imagePullSecrets;
  }

  if (form.spec.type == "DaemonSet") {
    if (!form.spec.daemonset.spec.template.spec.imagePullSecrets)
      Vue.set(form.spec.daemonset.spec.template.spec, "imagePullSecrets", []);
    return form.spec.daemonset.spec.template.spec.imagePullSecrets;
  }
}

export function imagePullSecretsHandler(form, data) {
  if (form.spec.type == "Deployment") {
    Vue.set(form.spec.deployment.spec.template.spec, "imagePullSecrets", data);
  }

  if (form.spec.type == "StatefulSet") {
    Vue.set(form.spec.statefulset.spec.template.spec, "imagePullSecrets", data);
  }

  if (form.spec.type == "CronJob") {
    Vue.set(form.spec.cronjob.spec.jobTemplate.spec.template.spec, "imagePullSecrets", data);
  }

  if (form.spec.type == "DaemonSet") {
    Vue.set(form.spec.daemonset.spec.template.spec, "imagePullSecrets", data);
  }
}
