<template>
  <div>
    <el-form :model="form" ref="form" label-position="top" size="small" :rules="formRules">
      <el-form-item :label="$t('serviceName')" prop="metadata.name" v-if="!update">
        <el-input v-model.trim="form.metadata.name"></el-input>
      </el-form-item>

      <el-form-item :label="$t('serviceType')">
        <el-select
          v-model="serviceType"
          @change="typeChange"
          :disabled="update && serviceType == 'Headless'"
          style="width: 100%;"
        >
          <el-option value="ClusterIP">
            <div class="select-option-info">
              <div>ClusterIP</div>
              <span class="help">{{ $t("ServiceTypeDesc.ClusterIP") }}</span>
            </div>
          </el-option>
          <el-option value="Headless" :disabled="update">
            <div class="select-option-info">
              <div>Headless</div>
              <span class="help">{{ $t("ServiceTypeDesc.ClusterIP") }}</span>
            </div>
          </el-option>
          <el-option value="LoadBalancer">
            <div class="select-option-info">
              <div>LoadBalancer</div>
              <span class="help">{{ $t("ServiceTypeDesc.LoadBalancer") }}</span>
            </div>
          </el-option>
          <el-option value="NodePort">
            <div class="select-option-info">
              <div>NodePort</div>
              <span class="help">{{ $t("ServiceTypeDesc.NodePort") }}</span>
            </div>
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item :label="$t('nodePort')">
        <node-port :ports="form.spec.ports" type="service" :serviceType="serviceType" />
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import NodePort from "./NodePort";
import { isEmpty, cloneDeep, includes, uniq } from "lodash";

export default {
  props: {
    serviceForm: {
      type: Object
    },
    update: {
      type: Boolean,
      default: false
    },
    namespace: {
      type: String
    },
    locationList: {
      type: Array
    }
  },
  components: {
    NodePort
  },

  data() {
    let validateIngressPort = (rule, value, callback) => {
      if (includes(this.ports, value)) {
        callback();
      } else {
        callback(new Error(this.$t("service.rules.ingressPort")));
      }
    };

    return {
      tableData: [],
      drawer: false,
      serviceType: "ClusterIP",
      form: {},

      formRules: {
        "metadata.name": [
          { required: true, message: this.$t("rules.require"), trigger: "blur" },
          {
            pattern: /^[a-z]([-a-z0-9]*[a-z0-9])?$/,
            message: this.$t("rules.pattern"),
            trigger: "blur"
          }
        ],
        "metadata.annotations.ingress-host": [{ required: true, message: this.$t("rules.require"), trigger: "blur" }],
        "metadata.annotations.ingress-port": [
          { required: true, message: this.$t("rules.require"), trigger: "blur" },
          { validator: validateIngressPort, trigger: "blur" }
        ],

        "metadata.annotations.ingress-subpath": [
          { required: true, message: this.$t("rules.require"), trigger: "blur" }
        ],

        "metadata.annotations.ingress-access-type": [
          { required: true, message: this.$t("rules.require"), trigger: "change" }
        ]
      }
    };
  },

  computed: {
    ports() {
      return uniq(this.form.spec.ports.map(item => item.port.toString()));
    }
  },

  created() {
    this.form = cloneDeep(this.serviceForm);

    if (isEmpty(this.form.metadata.annotations)) {
      this.$set(this.form.metadata, "annotations", {});
      this.$set(this.form.metadata.annotations, "use-ingress", false);
    }

    if ("clusterIP" in this.form.spec && this.form.spec.clusterIP == "None" && this.form.spec.type == "ClusterIP") {
      this.serviceType = "Headless";
    } else {
      this.serviceType = this.form.spec.type;
    }
  },

  methods: {
    typeChange(val) {
      if (val == "ClusterIP") {
        this.form.spec.type = "ClusterIP";
        if (!this.update) this.form.spec.clusterIP = "";
      }
      if (val == "LoadBalancer") {
        this.form.spec.type = "LoadBalancer";
        if (!this.update) this.form.spec.clusterIP = "";
      }
      if (val == "Headless") {
        this.form.spec.type = "ClusterIP";
        this.form.spec.clusterIP = "None";
      }
      if (val == "NodePort") {
        this.form.spec.type = "NodePort";
        if (!this.update) this.form.spec.clusterIP = "";
      }
    }
  }
};
</script>
