import { render, staticRenderFns } from "./NodePort.vue?vue&type=template&id=85ea2afa&scoped=true&"
import script from "./NodePort.vue?vue&type=script&lang=js&"
export * from "./NodePort.vue?vue&type=script&lang=js&"
import style0 from "./NodePort.vue?vue&type=style&index=0&id=85ea2afa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "85ea2afa",
  null
  
)

export default component.exports