<template>
  <div v-loading="loading">
    <!-- 为了兼容$refs["pvcForm"].$refs["form"].validate方法 -->

    <el-button
      type="primary"
      size="small"
      style="margin-bottom: 10px;"
      plain
      v-if="pvcList.length > 0"
      @click="form.persistentVolumeClaims.push({ claimName: '', rootPath: '' })"
    >
      {{ $t("handle.add") }}
    </el-button>

    <div v-else class="help">
      {{ $t("emptyInLocation", [`Namespcae: ${namespace}`]) }}
    </div>

    <el-form :model="form" ref="form" label-position="top" size="small">
      <el-card
        v-for="(config, index) in form.persistentVolumeClaims"
        :key="index"
        shadow="never"
        style="margin-bottom: 10px;"
      >
        <div slot="header" class="clearfix">
          <span>{{ $t("PersistentVolume") }}{{ index + 1 }}</span>
          <el-button
            style="float: right; padding: 3px 0"
            type="text"
            size="small"
            @click="form.persistentVolumeClaims.splice(index, 1)"
          >
            {{ $t("handle.delete") }}
          </el-button>
        </div>
        <el-form-item
          :label="$t('persistentVolumeName')"
          :prop="'persistentVolumeClaims.' + index + '.claimName'"
          :rules="{ required: true, message: $t('rules.require'), trigger: 'change' }"
        >
          <el-select style="width: 100%;" v-model="config.claimName" filterable>
            <el-option
              v-for="item in pvcList"
              :key="item.metadata.name"
              :value="item.metadata.name"
              :label="item.metadata.name"
              :disabled="includes(existedNames, item.metadata.name)"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('persistentVolumePath')"
          :prop="'persistentVolumeClaims.' + index + '.rootPath'"
          :rules="[
            { required: true, message: $t('rules.require'), trigger: 'blur' },
            { validator: validateRootPath, trigger: 'blur' }
          ]"
        >
          <el-input v-model.trim="config.rootPath"></el-input>
        </el-form-item>
      </el-card>
    </el-form>
  </div>
</template>

<script>
import { cloneDeep, includes } from "lodash";
import { pvcListByLocation } from "api/pv";
export default {
  props: {
    namespace: {
      type: String
    },

    pvc: {
      type: Array,
      default() {
        return [];
      }
    },

    disabledNames: {
      type: Array,
      default() {
        return [];
      }
    },

    locationList: {
      type: Array
    }
  },

  data() {
    return {
      form: {
        persistentVolumeClaims: []
      },
      pvcList: [],
      loading: false
    };
  },

  computed: {
    existedNames() {
      return [...this.form.persistentVolumeClaims.map(item => item.claimName), ...this.disabledNames];
    }
  },

  mounted() {
    this.getPvcList();

    let pvcTemp = cloneDeep(this.pvc);
    this.form.persistentVolumeClaims = pvcTemp;
  },

  methods: {
    includes,

    validateRootPath(rule, value, callback) {
      if (value === "/") {
        callback(new Error(this.$t("rules.pattern")));
      }
      callback();
    },

    getPvcList() {
      this.loading = true;
      if (this.namespace) {
        pvcListByLocation({
          vendor: this.locationList[0],
          region: this.locationList[1],
          cluster: this.locationList[2],
          namespace: this.namespace
        }).then(response => {
          this.loading = false;
          if (response.code === 0) {
            this.pvcList = response.data.items;
          }
        });
      }
    }
  }
};
</script>
