<template>
  <div>
    <el-popover width="180" v-model="visible" v-if="type == 'container'">
      <p class="port-type">{{ $t("tips.addContainerPorts") }}：</p>

      <el-select v-model="containerPortValue" size="small" style="margin-bottom: 10px;" filterable>
        <el-option v-for="item in containerPortOptions" :key="item.name" :label="item.name" :value="item.name">
        </el-option>
      </el-select>

      <div style="text-align: right; margin: 0">
        <el-button size="mini" type="text" @click="visible = false">{{ $t("handle.cancel") }}</el-button>
        <el-button type="primary" size="mini" @click="addContainerPort">{{ $t("handle.submit") }}</el-button>
      </div>

      <el-button slot="reference" icon="el-icon-plus" style="margin-bottom: 10px;" type="primary" plain>
        {{ $t("handle.addNodePort") }}
      </el-button>
    </el-popover>

    <el-button type="primary" size="small" icon="el-icon-plus" style="margin-bottom: 10px;" @click="add" plain v-else>
      {{ $t("handle.addNodePort") }}
    </el-button>

    <div v-for="(item, index) in ports" :key="index">
      <el-row :gutter="10" v-if="type == 'container'" style="margin-bottom: 10px;">
        <el-col :span="8">
          <el-input v-model.trim="item.name">
            <template slot="prepend">
              {{ $t("name") }}
            </template>
          </el-input>
        </el-col>

        <el-col :span="8">
          <el-popover ref="popover" placement="top" width="320" trigger="focus">
            <img :src="containerPortSVG" />

            <el-input
              v-model.number="item.containerPort"
              :min="0"
              oninput="value=value.replace(/[^0-9.]/g,'')"
              slot="reference"
            >
              <template slot="prepend">
                {{ $t("port") }}
              </template>
            </el-input>
          </el-popover>
        </el-col>

        <el-col :span="6">
          <el-select v-model="item.protocol" style="width: 100%;">
            <el-option label="TCP" value="TCP"></el-option>
            <el-option label="UDP" value="UDP"></el-option>
          </el-select>
        </el-col>

        <el-col :span="2">
          <el-button icon="el-icon-delete" circle @click="ports.splice(index, 1)"></el-button>
        </el-col>
      </el-row>

      <el-row :gutter="10" v-if="type == 'service'" style="margin-bottom: 10px;">
        <el-col :span="showNodePort ? 4 : 6">
          <el-input v-model.trim="item.name">
            <template slot="prepend">
              {{ $t("name") }}
            </template>
          </el-input>
        </el-col>

        <el-col :span="showNodePort ? 4 : 6">
          <el-popover ref="popover" placement="top" width="320" trigger="focus">
            <img :src="portSVG" />

            <el-input v-model.number="item.port" slot="reference" :min="0" oninput="value=value.replace(/[^0-9.]/g,'')">
              <template slot="prepend">
                {{ $t("port") }}
              </template>
            </el-input>
          </el-popover>
        </el-col>

        <el-col :span="6" v-if="showNodePort">
          <el-popover ref="popover" placement="top" width="320" trigger="focus">
            <img :src="nodeportSVG" />

            <el-input
              v-model.number="item.nodePort"
              slot="reference"
              :min="0"
              oninput="value=value.replace(/[^0-9.]/g,'')"
            >
              <template slot="prepend">
                {{ $t("nodePort") }}
              </template>
            </el-input>
          </el-popover>
        </el-col>

        <el-col :span="6">
          <el-popover ref="popover" placement="top" width="320" trigger="focus">
            <img :src="containerPortSVG" />

            <el-input
              v-model.number="item.targetPort"
              slot="reference"
              :min="0"
              oninput="value=value.replace(/[^0-9.]/g,'')"
            >
              <template slot="prepend">
                {{ $t("targetPort") }}
              </template>
            </el-input>
          </el-popover>
        </el-col>

        <el-col :span="3">
          <el-select v-model="item.protocol" style="width: 100%;">
            <el-option label="TCP" value="TCP"></el-option>
            <el-option label="UDP" value="UDP"></el-option>
          </el-select>
        </el-col>

        <el-col :span="1">
          <el-button icon="el-icon-delete" circle @click="ports.splice(index, 1)"></el-button>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import containerPortSVG from "@/assets/port/container-port.svg";
import portSVG from "@/assets/port/port.svg";
import nodeportSVG from "@/assets/port/nodeport.svg";

export default {
  name: "NodePort",
  props: {
    ports: Array,
    type: String,
    serviceType: String
  },

  data() {
    return {
      visible: false,
      containerPortSVG,
      portSVG,
      nodeportSVG,
      containerPortOptions: [
        {
          name: "customize",
          containerPort: 8080
        },

        {
          name: "https",
          containerPort: 443
        },

        {
          name: "http",
          containerPort: 80
        },

        {
          name: "mysql",
          containerPort: 3306
        },

        {
          name: "postgre",
          containerPort: 5432
        },

        {
          name: "redis",
          containerPort: 6379
        },

        {
          name: "web",
          containerPort: 8080
        }
      ],

      containerPortValue: "https"
    };
  },

  computed: {
    showNodePort() {
      return this.serviceType == "LoadBalancer" || this.serviceType == "NodePort";
    }
  },

  methods: {
    add() {
      if (this.type == "container")
        this.ports.push({
          name: `port${this.ports.length + 1}`,
          containerPort: 8080 + Number(this.ports.length),
          protocol: "TCP"
        });

      if (this.type == "service")
        this.ports.push({
          name: `port${this.ports.length + 1}`,
          port: 8080 + Number(this.ports.length),
          targetPort: 8080 + Number(this.ports.length),
          protocol: "TCP"
        });
    },

    addContainerPort() {
      this.visible = false;

      for (let item of this.containerPortOptions) {
        if (item.name === this.containerPortValue) {
          this.ports.push({
            ...item,
            protocol: "TCP"
          });
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
.port-type {
  font-size: 12px;
  color: $color-sub;
  margin-bottom: 10px;
}
</style>
