<template>
  <el-form :model="form" ref="form" size="small" :rules="formRules" label-position="top">
    <el-row :gutter="20">
      <el-col :span="12">
        <el-form-item :label="$t('containerName')" prop="name">
          <el-input v-model.trim="form.name"></el-input>
        </el-form-item>
      </el-col>

      <el-col :span="12">
        <el-form-item :label="$t('image')" prop="image">
          <image-select :data.sync="form.image" :locationList="$attrs.locationList" />
        </el-form-item>
      </el-col>
    </el-row>

    <el-tabs v-model="collapseVal" type="border-card">
      <el-tab-pane :label="$t('containerQuotaConf')" name="limits">
        <el-form-item>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item :label="$t('cpu')" prop="resources.limits.cpu">
                <el-input v-model="form.resources.limits.cpu">
                  <el-select v-model="form.resources.limits.cpuUnit" slot="append" style="width: 80px;">
                    <el-option label="Core" value="Core"></el-option>
                    <el-option label="m" value="m"></el-option>
                  </el-select>
                </el-input>

                <span class="help">1Core=1000m</span>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item :label="$t('cpuOversell')">
                <el-slider
                  v-model="form.resources.cpuOversell"
                  :min="1"
                  :max="100"
                  :step="10"
                  :marks="marks"
                  style="padding: 0 10px;"
                >
                </el-slider>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item :label="$t('memory')" prop="resources.limits.memory">
                <el-input v-model="form.resources.limits.memory">
                  <el-select v-model="form.resources.limits.memoryUnit" slot="append" style="width: 80px;">
                    <el-option label="K" value="K"></el-option>
                    <el-option label="M" value="M"></el-option>
                    <el-option label="G" value="G"></el-option>
                  </el-select>
                </el-input>

                <span class="help">1024K=1M / 1024M=1G</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('memOversell')">
                <el-slider
                  v-model="form.resources.memOversell"
                  :min="1"
                  :max="100"
                  :step="10"
                  :marks="marks"
                  style="padding: 0 10px;"
                >
                </el-slider>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form-item>
      </el-tab-pane>

      <el-tab-pane :label="$t('portConf')" name="network">
        <el-form-item :label="$t('nodePort')" prop="ports">
          <node-port :ports="form.ports" type="container" />
        </el-form-item>
      </el-tab-pane>

      <el-tab-pane :label="$t('env')" name="env">
        <el-form-item>
          <env :data="form.env" :namespace="$attrs.namespace" :locationList="$attrs.locationList" />
        </el-form-item>
      </el-tab-pane>

      <el-tab-pane :label="$t('ConfigMap')" name="config">
        <div class="conf-item-introduce">
          <div class="title">ConfigMap</div>
          <span>{{ $t("tips.containerConfigMap") }}</span>
          <a href="https://kubernetes.io/zh/docs/concepts/configuration/configmap/" target="_blank">
            <i class="el-icon-info"></i>
            [{{ $t("handle.moreConf") }}]
          </a>
        </div>

        <slot name="confMap"></slot>

        <div class="conf-item-introduce">
          <div class="title">Secret</div>
          <span>{{ $t("tips.containerSecret") }}</span>
          <a href="https://kubernetes.io/zh/docs/concepts/configuration/secret/" target="_blank">
            <i class="el-icon-info"></i>
            [{{ $t("handle.moreConf") }}]
          </a>
        </div>

        <slot name="secret"></slot>
      </el-tab-pane>

      <el-tab-pane :label="$t('volume')" name="volume">
        <div class="conf-item-introduce">
          <div class="title">Volume</div>
          <span>{{ $t("tips.containerVolume") }}</span>
          <a href="https://kubernetes.io/zh/docs/concepts/storage/volumes/" target="_blank">
            <i class="el-icon-info"></i>
            [{{ $t("handle.moreConf") }}]
          </a>
        </div>

        <slot name="volume"></slot>

        <div class="conf-item-introduce">
          <div class="title">PersistentVolume</div>
          <span>
            {{ $t("tips.containerPersistentVolume") }}
          </span>
          <a href="https://kubernetes.io/zh/docs/concepts/storage/persistent-volumes/" target="_blank">
            <i class="el-icon-info"></i>
            [{{ $t("handle.moreConf") }}]
          </a>
        </div>

        <slot name="pvc"></slot>
      </el-tab-pane>

      <el-tab-pane :label="$t('liftCycle')" name="liftCycle">
        <div class="conf-item-introduce">
          <div class="title">
            {{ $t("tips.containerLiftCycleTitle") }}
          </div>
          <span>
            {{ $t("tips.containerLiftCycle") }}
          </span>
          <a
            href="https://kubernetes.io/zh/docs/tasks/inject-data-application/define-command-argument-container/"
            target="_blank"
          >
            <i class="el-icon-info"></i>
            [{{ $t("handle.moreConf") }}]
          </a>
        </div>

        <div style="margin-bottom: 10px;">
          <el-checkbox v-model="visible.startCommand" :label="$t('startCommand')" border size="small"></el-checkbox>
          <el-checkbox v-model="visible.postStart" :label="$t('postStart')" border size="small"></el-checkbox>
          <el-checkbox v-model="visible.preStop" :label="$t('preStop')" border size="small"></el-checkbox>
        </div>

        <div v-if="visible.startCommand">
          <div class="drawer-item-title">
            {{ $t("startCommand") }}
            <span class="help">{{ $t("tips.startCommand") }}</span>
          </div>

          <el-row :gutter="10" v-for="(item, index) in form.command" :key="index" type="flex" align="bottom">
            <el-col :span="12">
              <el-form-item :label="$t('runningCommand') + (index + 1)">
                <el-input v-model="form.command[index]"> </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item>
                <el-button size="small" @click="form.command.splice(index, 1)">{{ $t("handle.delete") }}</el-button>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item>
            <el-button size="small" @click="form.command.push('')">+ 添加运行命令</el-button>
          </el-form-item>

          <el-row :gutter="10" v-for="(item, index) in form.args" :key="index" type="flex" align="bottom">
            <el-col :span="12">
              <el-form-item :label="$t('runningParams') + (index + 1)">
                <el-input v-model="form.args[index]"> </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item>
                <el-button size="small" @click="form.args.splice(index, 1)">{{ $t("handle.delete") }}</el-button>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item>
            <el-button size="small" @click="form.args.push('')">+ 添加运行参数</el-button>
          </el-form-item>
        </div>

        <div v-if="visible.postStart">
          <div class="drawer-item-title">
            {{ $t("postStart") }}
            <span class="help">{{ $t("tips.postStart") }}</span>
          </div>

          <el-form-item :label="$t('handleType')" prop="lifecycle.postStart">
            <handle-type :data.sync="form.lifecycle.postStart" />
          </el-form-item>

          <type-form :data="form.lifecycle.postStart" />
        </div>

        <div v-if="visible.preStop">
          <div class="drawer-item-title">
            {{ $t("preStop") }}
            <span class="help">
              {{ $t("tips.preStop") }}
            </span>
          </div>

          <el-form-item :label="$t('handleType')" prop="lifecycle.preStop">
            <handle-type :data.sync="form.lifecycle.preStop" />
          </el-form-item>

          <type-form :data="form.lifecycle.preStop" />
        </div>
      </el-tab-pane>

      <el-tab-pane :label="$t('health')" name="probes">
        <div class="conf-item-introduce">
          <div class="title">
            {{ $t("tips.containerHealthCheckTitle") }}
          </div>
        </div>

        <div style="margin-bottom: 10px;">
          <el-checkbox v-model="visible.livenessProbe" :label="$t('livenessProbe')" border size="small"></el-checkbox>
          <el-checkbox v-model="visible.readinessProbe" :label="$t('readinessProbe')" border size="small"></el-checkbox>
        </div>

        <div v-if="visible.livenessProbe">
          <div class="drawer-item-title">
            {{ $t("livenessProbe") }}
            <span class="help">
              {{ $t("tips.livenessProbe") }}
            </span>
          </div>

          <el-form-item :label="$t('checkType')" prop="livenessProbe">
            <handle-type :data.sync="form.livenessProbe" />
          </el-form-item>

          <type-form :data="form.livenessProbe" />

          <el-form-item :label="$t('initialDelaySeconds')" prop="livenessProbe.initialDelaySeconds">
            <el-input
              v-model.number="form.livenessProbe.initialDelaySeconds"
              :min="0"
              oninput="value=value.replace(/[^0-9.]/g,'')"
            >
              <template slot="append">
                {{ $t("unit.second") }}
              </template>
            </el-input>
          </el-form-item>
          <el-form-item :label="$t('periodSeconds')" prop="livenessProbe.periodSeconds">
            <el-input
              v-model.number="form.livenessProbe.periodSeconds"
              :min="0"
              oninput="value=value.replace(/[^0-9.]/g,'')"
            >
              <template slot="append">
                {{ $t("unit.second") }}
              </template>
            </el-input>
          </el-form-item>

          <el-form-item :label="$t('timeoutSeconds')" prop="livenessProbe.timeoutSeconds">
            <el-input
              v-model.number="form.livenessProbe.timeoutSeconds"
              :min="0"
              oninput="value=value.replace(/[^0-9.]/g,'')"
            >
              <template slot="append">
                {{ $t("unit.second") }}
              </template>
            </el-input>
          </el-form-item>
        </div>

        <div v-if="visible.readinessProbe">
          <div class="drawer-item-title">
            {{ $t("readinessProbe") }}
            <span class="help">
              {{ $t("tips.readinessProbe") }}
            </span>
          </div>

          <el-form-item :label="$t('checkType')" prop="readinessProbe">
            <handle-type :data.sync="form.readinessProbe" />
          </el-form-item>

          <type-form :data="form.readinessProbe" />

          <el-form-item :label="$t('initialDelaySeconds')" prop="readinessProbe.initialDelaySeconds">
            <el-input
              v-model.number="form.readinessProbe.initialDelaySeconds"
              :min="0"
              oninput="value=value.replace(/[^0-9.]/g,'')"
            >
              <template slot="append">
                {{ $t("unit.second") }}
              </template>
            </el-input>
          </el-form-item>
          <el-form-item :label="$t('periodSeconds')" prop="readinessProbe.periodSeconds">
            <el-input
              v-model.number="form.readinessProbe.periodSeconds"
              :min="0"
              oninput="value=value.replace(/[^0-9.]/g,'')"
            >
              <template slot="append">
                {{ $t("unit.second") }}
              </template>
            </el-input>
          </el-form-item>

          <el-form-item :label="$t('timeoutSeconds')" prop="readinessProbe.timeoutSeconds">
            <el-input
              v-model.number="form.readinessProbe.timeoutSeconds"
              :min="0"
              oninput="value=value.replace(/[^0-9.]/g,'')"
            >
              <template slot="append">
                {{ $t("unit.second") }}
              </template>
            </el-input>
          </el-form-item>
        </div>
      </el-tab-pane>
    </el-tabs>
  </el-form>
</template>

<script>
import HandleType from "./HandleType";
import TypeForm from "./TypeForm";

import NodePort from "./NodePort";
import { cloneDeep, isEmpty } from "lodash";

export default {
  components: {
    HandleType,
    TypeForm,
    NodePort
  },
  props: {
    containerForm: {
      type: Object
    }
  },
  data() {
    return {
      collapseVal: "limits",
      formRules: {
        name: [
          { required: true, message: this.$t("rules.require"), trigger: "blur" },
          {
            pattern: /[a-z0-9]([-a-z0-9][a-z0-9])?(\.[a-z0-9]([-a-z0-9][a-z0-9])?)*/,
            message: this.$t("rules.pattern"),
            trigger: "blur"
          }
        ],
        image: { required: true, message: this.$t("rules.require"), trigger: "change" }
      },

      visible: {
        startCommand: false,
        postStart: false,
        preStop: false,
        livenessProbe: false,
        readinessProbe: false
      },

      form: {},

      marks: {
        10: "10%",
        30: "30%",
        50: "50%",
        70: "70%",
        90: "90%"
      }
    };
  },

  watch: {
    "visible.startCommand": function(newVal) {
      if (newVal) {
        if (!this.form.command) this.$set(this.form, "command", []);
        if (!this.form.args) this.$set(this.form, "args", []);
      } else {
        delete this.form.command;
        delete this.form.args;
      }
    },

    "visible.postStart": function(newVal) {
      if (newVal) {
        if (!this.form.lifecycle) this.$set(this.form, "lifecycle", {});

        if (!this.form.lifecycle.postStart) {
          this.$set(this.form.lifecycle, "postStart", {});
          this.$set(this.form.lifecycle.postStart, "exec", { command: [] });
        }
      } else {
        delete this.form.lifecycle.postStart;
        if (isEmpty(this.form.lifecycle)) delete this.form.lifecycle;
      }
    },

    "visible.preStop": function(newVal) {
      if (newVal) {
        if (!this.form.lifecycle) this.$set(this.form, "lifecycle", {});
        if (!this.form.lifecycle.preStop) {
          this.$set(this.form.lifecycle, "preStop", {});
          this.$set(this.form.lifecycle.preStop, "exec", { command: [] });
        }
      } else {
        delete this.form.lifecycle.preStop;
        if (isEmpty(this.form.lifecycle)) delete this.form.lifecycle;
      }
    },

    "visible.livenessProbe": function(newVal) {
      if (newVal) {
        if (!this.form.livenessProbe) {
          this.$set(this.form, "livenessProbe", {});
          this.$set(this.form.livenessProbe, "httpGet", { path: "", port: 8080, scheme: "HTTP" });
          this.$set(this.form.livenessProbe, "initialDelaySeconds", 30);
          this.$set(this.form.livenessProbe, "periodSeconds", 10);
          this.$set(this.form.livenessProbe, "timeoutSeconds", 5);
        }
      } else {
        delete this.form.livenessProbe;
      }
    },

    "visible.readinessProbe": function(newVal) {
      if (newVal) {
        if (!this.form.readinessProbe) {
          this.$set(this.form, "readinessProbe", {});
          this.$set(this.form.readinessProbe, "httpGet", { path: "", port: 8080, scheme: "HTTP" });
          this.$set(this.form.readinessProbe, "initialDelaySeconds", 30);
          this.$set(this.form.readinessProbe, "periodSeconds", 10);
          this.$set(this.form.readinessProbe, "timeoutSeconds", 5);
        }
      } else {
        delete this.form.readinessProbe;
      }
    }
  },

  created() {
    this.form = cloneDeep(this.containerForm);

    if (!this.form.env) this.$set(this.form, "env", []);

    if ("command" in this.form || "args" in this.form) {
      this.visible.startCommand = true;

      if (!this.form.command) this.form.command = [];
      if (!this.form.args) this.form.args = [];
    }

    if (this.form.lifecycle && "postStart" in this.form.lifecycle) {
      this.visible.postStart = true;
    }

    if (this.form.lifecycle && "preStop" in this.form.lifecycle) {
      this.visible.preStop = true;
    }

    if ("livenessProbe" in this.form) {
      this.visible.livenessProbe = true;
    }

    if ("readinessProbe" in this.form) {
      this.visible.readinessProbe = true;
    }

    if (!this.form.resources.limits) {
      this.form.resources.limits = {
        cpu: null,
        memory: null
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
.drawer-item-title {
  background-color: #e8f4ff;
  color: $color-primary;
  border: 1px solid#d1e9ff;
  border-radius: 4px;
  font-weight: 500;
  padding: 10px;
  font-size: 12px;
  margin-bottom: 20px;
}
</style>
