<template>
  <div>
    <el-button
      type="primary"
      size="small"
      style="margin-bottom: 10px;"
      @click="
        form.volumes.push({ name: '', volumeSource: { hostPath: { hostPath: '', mountPath: '', readOnly: true } } })
      "
      plain
    >
      {{ $t("handle.add") }}
    </el-button>

    <el-form :model="form" ref="form" label-position="top" size="small">
      <el-card v-for="(volume, index) in form.volumes" :key="index" shadow="never" style="margin-bottom: 10px;">
        <div slot="header" class="clearfix">
          <span>{{ $t("volume") }} {{ index + 1 }}</span>
          <el-button
            style="float: right; padding: 3px 0"
            type="text"
            size="small"
            @click="form.volumes.splice(index, 1)"
          >
            {{ $t("handle.delete") }}
          </el-button>
        </div>

        <el-form-item
          :label="$t('volumeName')"
          :prop="'volumes.' + index + '.name'"
          :rules="{ required: true, message: $t('rules.require'), trigger: 'blur' }"
        >
          <el-input v-model.trim="volume.name"></el-input>
        </el-form-item>

        <el-form-item
          :label="$t('volumePath')"
          :prop="'volumes.' + index + '.volumeSource.hostPath.hostPath'"
          :rules="{ required: true, message: $t('rules.require'), trigger: 'blur' }"
        >
          <el-input v-model.trim="volume.volumeSource.hostPath.hostPath"></el-input>
        </el-form-item>

        <el-form-item
          :label="$t('volumeMountPath')"
          :prop="'volumes.' + index + '.volumeSource.hostPath.mountPath'"
          :rules="{ required: true, message: $t('rules.require'), trigger: 'blur' }"
        >
          <el-input v-model.trim="volume.volumeSource.hostPath.mountPath"></el-input>
        </el-form-item>

        <el-form-item :label="$t('readonly')">
          <el-switch v-model="volume.volumeSource.hostPath.readOnly"></el-switch>
        </el-form-item>
      </el-card>
    </el-form>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
export default {
  props: {
    volumes: {
      type: Array,
      default() {
        return [];
      }
    }
  },

  data() {
    return {
      form: {
        volumes: []
      }
    };
  },

  mounted() {
    this.form.volumes = cloneDeep(this.volumes);
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";

.config-item {
  @include resource-card();
  line-height: 1 !important;
}
</style>
