<template>
  <div>
    <el-form :model="form" ref="form" label-position="top" size="small" :rules="rules">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item :label="$t('applicationName')" prop="metadata.name" v-if="!update">
            <el-input v-model.trim="form.metadata.name"></el-input>
            <span class="help">{{ $t("tips.application") }}</span>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item :label="$t('replicas')" v-if="['Deployment', 'StatefulSet'].includes(type)">
            <el-input
              v-model.number="form.spec[type.toLowerCase()].spec.replicas"
              :min="0"
              oninput="value=value.replace(/[^0-9.]/g,'')"
            ></el-input>

            <span class="help">{{ $t("tips.replicas") }}</span>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item :label="$t('schedule')" prop="spec.cronjob.spec.schedule" v-if="type == 'CronJob'">
            <el-input v-model="form.spec.cronjob.spec.schedule"></el-input>
            <span class="help">{{ $t("tips.schedule") }}</span>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item
        :label="$t('terminationGracePeriodSeconds')"
        v-if="['Deployment', 'StatefulSet', 'DaemonSet'].includes(type)"
      >
        <el-input
          v-model.number="form.spec[type.toLowerCase()].spec.template.spec.terminationGracePeriodSeconds"
          :min="0"
          oninput="value=value.replace(/[^0-9.]/g,'')"
        >
          <template slot="append">{{ $t("unit.second") }}</template>
        </el-input>
        <span class="help">{{ $t("tips.terminationGracePeriodSeconds") }}</span>
      </el-form-item>

      <el-tabs type="border-card">
        <el-tab-pane :label="$t('StatefulSetConf')" v-if="type == 'StatefulSet' && !update">
          <el-form-item :label="$t('podManagementPolicy')" v-if="type == 'StatefulSet' && !update">
            <el-radio-group v-model="form.spec.statefulset.spec.podManagementPolicy">
              <el-radio-button label="OrderedReady">{{ $t("OrderedReady") }}</el-radio-button>
              <el-radio-button label="Parallel">{{ $t("Parallel") }}</el-radio-button>
            </el-radio-group>
            <span class="help">{{ $t("tips.podManagementPolicy") }}</span>
          </el-form-item>

          <el-form-item :label="$t('volumeClaimTemplates')" v-if="type == 'StatefulSet' && !update">
            <el-button type="primary" size="small" style="margin-bottom: 10px;" @click="addVolumeClaimTemplates" plain>
              {{ $t("handle.add") }}
            </el-button>

            <el-form :model="form.spec" ref="templateForm" size="small">
              <el-card
                v-for="(template, index) in form.spec.volumeClaimTemplates"
                :key="index"
                shadow="never"
                style="margin-bottom: 10px;"
              >
                <div slot="header" class="clearfix">
                  <span>{{ $t("volumeClaimTemplates") }}{{ index + 1 }}</span>
                  <el-button
                    style="float: right; padding: 3px 0"
                    type="text"
                    size="small"
                    @click="form.spec.volumeClaimTemplates.splice(index, 1)"
                    >{{ $t("handle.delete") }}</el-button
                  >
                </div>

                <el-form-item :label="$t('volumeClaimTemplatesName')">
                  <el-input v-model.trim="template.metadata.name"></el-input>
                </el-form-item>

                <el-form-item :label="$t('persistentVolumePath')">
                  <el-input v-model.trim="template.rootPath"></el-input>
                </el-form-item>

                <el-form-item :label="$t('storageClassName')">
                  <el-select
                    v-model="template.storageClassName"
                    :loading="storageClassLoading"
                    @focus="initStorageClass"
                    filterable
                    style="width: 100%;"
                  >
                    <el-option
                      v-for="item in storageClassOptions"
                      :key="item.metadata.name"
                      :label="item.metadata.name"
                      :value="item.metadata.name"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>

                <el-form-item :label="$t('accessModes')">
                  <el-radio-group v-model="template.spec.accessModes[0]">
                    <el-radio-button label="ReadWriteOnce"></el-radio-button>
                    <el-radio-button label="ReadOnlyMany"></el-radio-button>
                    <el-radio-button label="ReadWriteMany"></el-radio-button>
                  </el-radio-group>
                </el-form-item>

                <el-form-item :label="$t('storage')">
                  <el-radio-group v-model="template.spec.resources.requests.storage">
                    <el-radio-button label="2Gi"></el-radio-button>
                    <el-radio-button label="8Gi"></el-radio-button>
                  </el-radio-group>
                </el-form-item>

                <el-form-item :label="$t('volumeMode')">
                  <el-radio-group v-model="template.spec.volumeMode">
                    <el-radio-button label="Filesystem"></el-radio-button>
                    <el-radio-button label="Block"></el-radio-button>
                  </el-radio-group>
                </el-form-item>
              </el-card>
            </el-form>
          </el-form-item>
        </el-tab-pane>

        <el-tab-pane :label="$t('CronJobConf')" v-if="type == 'CronJob'">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item :label="$t('concurrencyPolicy')" v-if="type == 'CronJob'">
                <el-radio-group v-model="form.spec.cronjob.spec.concurrencyPolicy">
                  <el-radio-button label="Allow"></el-radio-button>
                  <el-radio-button label="Forbid"></el-radio-button>
                  <el-radio-button label="Replace"></el-radio-button>
                </el-radio-group>

                <span class="help">{{ $t("tips.concurrencyPolicy") }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('suspend')" v-if="type == 'CronJob'">
                <el-radio-group v-model="form.spec.cronjob.spec.suspend">
                  <el-radio-button :label="true">ON</el-radio-button>
                  <el-radio-button :label="false">OFF</el-radio-button>
                </el-radio-group>

                <span class="help">{{ $t("tips.suspend") }}</span>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item :label="$t('startingDeadlineSeconds')" v-if="type == 'CronJob'">
                <el-input
                  v-model.number="form.spec.cronjob.spec.startingDeadlineSeconds"
                  :min="0"
                  oninput="value=value.replace(/[^0-9.]/g,'')"
                >
                  <template slot="append">{{ $t("unit.second") }}</template>
                </el-input>
                <span class="help">{{ $t("tips.startingDeadlineSeconds") }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item :label="$t('parallelism')" v-if="type == 'CronJob'">
                <el-input
                  v-model.number="form.spec.cronjob.spec.jobTemplate.spec.parallelism"
                  :min="0"
                  oninput="value=value.replace(/[^0-9.]/g,'')"
                ></el-input>
                <span class="help">{{ $t("tips.parallelism") }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item :label="$t('completions')" v-if="type == 'CronJob'">
                <el-input
                  v-model.number="form.spec.cronjob.spec.jobTemplate.spec.completions"
                  :min="0"
                  oninput="value=value.replace(/[^0-9.]/g,'')"
                ></el-input>
                <span class="help">{{ $t("tips.completions") }}</span>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item
                :label="$t('activeDeadlineSeconds')"
                prop="spec.cronjob.spec.jobTemplate.spec.activeDeadlineSeconds"
                :rules="{ required: true, message: $t('rules.require'), trigger: 'blur' }"
                v-if="type == 'CronJob'"
              >
                <el-input
                  v-model.number="form.spec.cronjob.spec.jobTemplate.spec.activeDeadlineSeconds"
                  :min="0"
                  oninput="value=value.replace(/[^0-9.]/g,'')"
                >
                  <template slot="append">{{ $t("unit.second") }}</template>
                </el-input>
                <span class="help">{{ $t("tips.activeDeadlineSeconds") }}</span>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item :label="$t('backoffLimit')" v-if="type == 'CronJob'">
                <el-input
                  v-model.number="form.spec.cronjob.spec.jobTemplate.spec.backoffLimit"
                  :min="0"
                  oninput="value=value.replace(/[^0-9.]/g,'')"
                ></el-input>
                <span class="help">{{ $t("tips.backoffLimit") }}</span>
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>

        <el-tab-pane :label="$t('strategyConf')" v-if="['Deployment', 'StatefulSet', 'DaemonSet'].includes(type)">
          <el-form-item :label="$t('updateStrategy')" v-if="type == 'Deployment'">
            <el-radio-group v-model="form.spec.deployment.spec.strategy.type">
              <el-radio-button label="RollingUpdate">RollingUpdate</el-radio-button>
              <el-radio-button label="Recreate">Recreate</el-radio-button>
            </el-radio-group>
            <span class="help">{{ $t("tips.updateStrategy") }}</span>
          </el-form-item>

          <el-form-item
            label="MaxUnavailable"
            v-if="
              type == 'Deployment' &&
                form.spec.deployment.spec.strategy.type == 'RollingUpdate' &&
                'maxUnavailable' in form.spec.deployment.spec.strategy.rollingUpdate
            "
          >
            <el-input
              v-model.number="form.spec.deployment.spec.strategy.rollingUpdate.maxUnavailable"
              :min="0"
              oninput="value=value.replace(/[^0-9.]/g,'')"
            ></el-input>

            <span class="help">
              {{ $t("tips.maxUnavailable") }}
            </span>
          </el-form-item>

          <el-form-item
            label="MaxSurge"
            v-if="
              type == 'Deployment' &&
                form.spec.deployment.spec.strategy.type == 'RollingUpdate' &&
                'maxSurge' in form.spec.deployment.spec.strategy.rollingUpdate
            "
          >
            <el-input
              v-model.number="form.spec.deployment.spec.strategy.rollingUpdate.maxSurge"
              :min="0"
              oninput="value=value.replace(/[^0-9.]/g,'')"
            ></el-input>

            <span class="help">
              {{ $t("tips.maxSurge") }}
            </span>
          </el-form-item>

          <el-form-item :label="$t('updateStrategy')" v-if="type == 'StatefulSet'">
            <el-radio-group v-model="form.spec.statefulset.spec.updateStrategy.type">
              <el-radio-button label="RollingUpdate">RollingUpdate</el-radio-button>
              <el-radio-button label="OnDelete">OnDelete</el-radio-button>
            </el-radio-group>
            <span class="help">{{ $t("tips.updateStrategy") }}</span>
          </el-form-item>

          <el-form-item
            :label="$t('partition')"
            v-if="
              type == 'StatefulSet' &&
                form.spec.statefulset.spec.updateStrategy.type == 'RollingUpdate' &&
                'rollingUpdate' in form.spec.statefulset.spec.updateStrategy
            "
          >
            <el-input
              v-model.number="form.spec.statefulset.spec.updateStrategy.rollingUpdate.partition"
              :min="0"
              oninput="value=value.replace(/[^0-9.]/g,'')"
            ></el-input>
            <span class="help">{{ $t("tips.partition") }}</span>
          </el-form-item>

          <el-form-item :label="$t('updateStrategy')" v-if="type == 'DaemonSet'">
            <el-radio-group v-model="form.spec.daemonset.spec.updateStrategy.type">
              <el-radio-button label="RollingUpdate">RollingUpdate</el-radio-button>
              <el-radio-button label="OnDelete">OnDelete</el-radio-button>
            </el-radio-group>
            <span class="help">{{ $t("tips.updateStrategy") }}</span>
          </el-form-item>

          <el-form-item
            label="MaxUnavailable"
            v-if="
              type == 'DaemonSet' &&
                form.spec.daemonset.spec.updateStrategy.type == 'RollingUpdate' &&
                'rollingUpdate' in form.spec.daemonset.spec.updateStrategy
            "
          >
            <el-input
              v-model.number="form.spec.daemonset.spec.updateStrategy.rollingUpdate.maxUnavailable"
              :min="0"
              oninput="value=value.replace(/[^0-9.]/g,'')"
            ></el-input>
            <span class="help">
              {{ $t("tips.maxUnavailable") }}
            </span>
          </el-form-item>
        </el-tab-pane>

        <el-tab-pane :label="$t('affinityAndTolerationsConf')">
          <el-form-item
            :label="$t('nodeAffinity')"
            v-if="
              'nodeAffinity' in getAffinity() &&
                'requiredDuringSchedulingIgnoredDuringExecution' in getAffinity().nodeAffinity
            "
          >
            <div slot="label">
              <div>{{ $t("nodeAffinity") }}</div>
              <span class="help">
                {{ $t("affinityTips.nodeAffinity") }}
              </span>
            </div>

            <el-button
              type="default"
              size="small"
              style="margin-bottom: 10px; "
              plain
              icon="plus"
              @click="
                getAffinity().nodeAffinity.requiredDuringSchedulingIgnoredDuringExecution.nodeSelectorTerms[0].matchExpressions.push(
                  { key: '', operator: 'In', values: [] }
                )
              "
            >
              + {{ $t("handle.addNodeLabels") }}
            </el-button>

            <el-row
              :gutter="20"
              v-for="(item, index) in getAffinity().nodeAffinity.requiredDuringSchedulingIgnoredDuringExecution
                .nodeSelectorTerms[0].matchExpressions"
              :key="index"
            >
              <el-col :span="11">
                <el-form-item>
                  <el-input v-model="item.key" size="small">
                    <template slot="prepend">
                      Key
                    </template>
                  </el-input>
                </el-form-item>
              </el-col>

              <el-col :span="11">
                <el-form-item>
                  <el-input v-model="item.values[0]" size="small">
                    <template slot="prepend">
                      Value
                    </template>
                  </el-input>
                </el-form-item>
              </el-col>

              <el-col :span="2">
                <el-button
                  icon="el-icon-delete"
                  circle
                  @click="
                    getAffinity().nodeAffinity.requiredDuringSchedulingIgnoredDuringExecution.nodeSelectorTerms[0].matchExpressions.splice(
                      index,
                      1
                    )
                  "
                  size="small"
                ></el-button>
              </el-col>
            </el-row>
          </el-form-item>

          <el-form-item
            v-if="
              'podAffinity' in getAffinity() &&
                'requiredDuringSchedulingIgnoredDuringExecution' in getAffinity().podAffinity
            "
          >
            <div slot="label">
              <div>{{ $t("podAffinity") }}</div>
              <span class="help">
                {{ $t("affinityTips.podAffinity") }}
              </span>
            </div>

            <el-button
              type="default"
              size="small"
              style="margin-bottom: 10px; "
              plain
              icon="plus"
              @click="
                getAffinity().podAffinity.requiredDuringSchedulingIgnoredDuringExecution[0].labelSelector.matchExpressions.push(
                  { key: '', operator: 'In', values: [] }
                )
              "
            >
              + {{ $t("handle.addPodAffinityLabels") }}
            </el-button>

            <el-row
              :gutter="20"
              v-for="(item, index) in getAffinity().podAffinity.requiredDuringSchedulingIgnoredDuringExecution[0]
                .labelSelector.matchExpressions"
              :key="index"
            >
              <el-col :span="8">
                <el-form-item>
                  <el-input
                    v-model="
                      getAffinity().podAffinity.requiredDuringSchedulingIgnoredDuringExecution[0].namespaces[index]
                    "
                    size="small"
                  >
                    <template slot="prepend">
                      Namespace
                    </template>
                  </el-input>
                </el-form-item>
              </el-col>

              <el-col :span="7">
                <el-form-item>
                  <el-input v-model="item.key" size="small">
                    <template slot="prepend">
                      Key
                    </template>
                  </el-input>
                </el-form-item>
              </el-col>

              <el-col :span="7">
                <el-form-item>
                  <el-input v-model="item.values[0]" size="small">
                    <template slot="prepend">
                      Value
                    </template>
                  </el-input>
                </el-form-item>
              </el-col>

              <el-col :span="2">
                <el-button
                  icon="el-icon-delete"
                  circle
                  @click="
                    getAffinity().podAffinity.requiredDuringSchedulingIgnoredDuringExecution[0].labelSelector.matchExpressions.splice(
                      index,
                      1
                    )
                  "
                  size="small"
                ></el-button>
              </el-col>
            </el-row>
          </el-form-item>

          <el-form-item
            :label="$t('affinityNodeKey')"
            v-if="
              'podAffinity' in getAffinity() &&
                'requiredDuringSchedulingIgnoredDuringExecution' in getAffinity().podAffinity &&
                getAffinity().podAffinity.requiredDuringSchedulingIgnoredDuringExecution[0].labelSelector
                  .matchExpressions.length > 0
            "
          >
            <el-input
              v-model="getAffinity().podAffinity.requiredDuringSchedulingIgnoredDuringExecution[0].topologyKey"
              size="small"
            >
            </el-input>

            <span class="help">{{ $t("affinityTips.nodeKey") }}</span>
          </el-form-item>

          <el-form-item
            v-if="
              'podAntiAffinity' in getAffinity() &&
                'requiredDuringSchedulingIgnoredDuringExecution' in getAffinity().podAntiAffinity
            "
          >
            <div slot="label">
              <div>{{ $t("podAntiAffinity") }}</div>
              <span class="help">
                {{ $t("affinityTips.podAntiAffinity") }}
              </span>
            </div>

            <el-button
              type="default"
              size="small"
              style="margin-bottom: 10px; "
              plain
              icon="plus"
              @click="
                getAffinity().podAntiAffinity.requiredDuringSchedulingIgnoredDuringExecution[0].labelSelector.matchExpressions.push(
                  { key: '', operator: 'In', values: [] }
                )
              "
            >
              + {{ $t("handle.addPodAntiAffinityLabels") }}
            </el-button>

            <el-row
              :gutter="20"
              v-for="(item, index) in getAffinity().podAntiAffinity.requiredDuringSchedulingIgnoredDuringExecution[0]
                .labelSelector.matchExpressions"
              :key="index"
            >
              <el-col :span="8">
                <el-form-item>
                  <el-input
                    v-model="
                      getAffinity().podAntiAffinity.requiredDuringSchedulingIgnoredDuringExecution[0].namespaces[index]
                    "
                    size="small"
                  >
                    <template slot="prepend">
                      Namespace
                    </template>
                  </el-input>
                </el-form-item>
              </el-col>

              <el-col :span="7">
                <el-form-item>
                  <el-input v-model="item.key" size="small">
                    <template slot="prepend">
                      Key
                    </template>
                  </el-input>
                </el-form-item>
              </el-col>

              <el-col :span="7">
                <el-form-item>
                  <el-input v-model="item.values[0]" size="small">
                    <template slot="prepend">
                      Value
                    </template>
                  </el-input>
                </el-form-item>
              </el-col>

              <el-col :span="2">
                <el-button
                  icon="el-icon-delete"
                  circle
                  @click="
                    getAffinity().podAntiAffinity.requiredDuringSchedulingIgnoredDuringExecution[0].labelSelector.matchExpressions.splice(
                      index,
                      1
                    )
                  "
                  size="small"
                ></el-button>
              </el-col>
            </el-row>
          </el-form-item>

          <el-form-item
            :label="$t('affinityNodeKey')"
            v-if="
              'podAntiAffinity' in getAffinity() &&
                'requiredDuringSchedulingIgnoredDuringExecution' in getAffinity().podAntiAffinity &&
                getAffinity().podAntiAffinity.requiredDuringSchedulingIgnoredDuringExecution[0].labelSelector
                  .matchExpressions.length > 0
            "
          >
            <el-input
              v-model="getAffinity().podAntiAffinity.requiredDuringSchedulingIgnoredDuringExecution[0].topologyKey"
              size="small"
            >
            </el-input>

            <span class="help">{{ $t("affinityTips.antiNodeKey") }}</span>
          </el-form-item>

          <el-form-item :label="$t('tolerations')">
            <div slot="label">
              <div>{{ $t("tolerations") }}</div>
              <span class="help">
                {{ $t("tolerationsDesc") }}
              </span>
            </div>

            <el-button
              type="default"
              size="small"
              style="margin-bottom: 10px; "
              plain
              icon="plus"
              @click="getTolerations().push({ key: '', operator: 'Equal', value: '', effect: 'NoSchedule' })"
            >
              + {{ $t("handle.addToleration") }}
            </el-button>

            <el-row :gutter="20" v-for="(item, index) in getTolerations()" :key="index">
              <el-col :span="6">
                <el-form-item>
                  <el-input v-model="item.key" size="small">
                    <template slot="prepend">
                      Key
                    </template>
                  </el-input>
                </el-form-item>
              </el-col>

              <el-col :span="5">
                <el-select
                  size="small"
                  v-model="item.operator"
                  style="width: 100%;"
                  placeholder="Operator"
                  @change="
                    () => {
                      item.value = '';
                    }
                  "
                >
                  <el-option label="Equal" value="Equal"> </el-option>
                  <el-option label="Exists" value="Exists"> </el-option>
                </el-select>
              </el-col>

              <el-col :span="6">
                <el-form-item>
                  <el-input v-model="item.value" size="small" :disabled="item.operator === 'Exists'">
                    <template slot="prepend">
                      Value
                    </template>
                  </el-input>
                </el-form-item>
              </el-col>

              <el-col :span="5">
                <el-select size="small" v-model="item.effect" style="width: 100%;" placeholder="Effect">
                  <el-option :label="$t('taintsNoSchedule')" value="NoSchedule"> </el-option>
                  <el-option :label="$t('taintsPreferNoSchedule')" value="PreferNoSchedule"> </el-option>
                  <el-option :label="$t('taintsNoExecute')" value="NoExecute"> </el-option>
                </el-select>
              </el-col>

              <el-col :span="2">
                <el-button
                  icon="el-icon-delete"
                  circle
                  @click="getTolerations().splice(index, 1)"
                  size="small"
                ></el-button>
              </el-col>
            </el-row>
          </el-form-item>
        </el-tab-pane>

        <el-tab-pane :label="$t('hostAliasesConf')">
          <el-form-item :label="$t('hostAliases')">
            <el-button
              size="small"
              type="primary"
              plain
              @click="getHostAliases().push({ ip: '', hostnames: [] })"
              style="margin-bottom: 10px;"
            >
              {{ $t("handle.addHostAlias") }}
            </el-button>

            <el-card v-for="(item, index) in getHostAliases()" :key="index" style="margin-bottom: 10px;" shadow="never">
              <div slot="header" class="clearfix">
                <span>
                  {{ $t("hostAliases") }}
                  {{ index + 1 }}
                </span>
                <el-button
                  style="float: right; padding: 3px 0"
                  type="text"
                  size="small"
                  @click="getHostAliases().splice(index, 1)"
                >
                  {{ $t("handle.delete") }}
                </el-button>
              </div>

              <el-form-item label="IP">
                <el-input v-model.trim="item.ip" style="width: 200px;"></el-input>
              </el-form-item>

              <el-form-item :label="$t('alias')">
                <el-tag
                  v-for="(alias, aliasIndex) in item.hostnames"
                  :key="aliasIndex"
                  closable
                  :disable-transitions="false"
                  @close="item.hostnames.splice(aliasIndex, 1)"
                >
                  {{ alias }}
                </el-tag>

                <el-input
                  v-if="item.inputVisible"
                  v-model="item.inputValue"
                  :ref="'alias.' + index"
                  size="small"
                  @keyup.enter.native="handleInputConfirm(item)"
                  @blur="handleInputConfirm(item)"
                  style="width: 120px;"
                >
                </el-input>
                <el-button v-else size="small" @click="showInput(item, index)">+ {{ $t("handle.addAlias") }}</el-button>
              </el-form-item>
            </el-card>
          </el-form-item>
        </el-tab-pane>

        <el-tab-pane label="拉取私有镜像设置">
          <el-form-item label="选择密钥">
            <div v-if="vendor && region && cluster && form.metadata.namespace">
              <el-select
                style="width: 50%;"
                multiple
                size="small"
                filterable
                :loading="secretListLoading"
                v-model="imagePullSecrets"
              >
                <template #prefix v-if="secretListLoading">
                  <i class="el-icon-loading" style="margin-left: 4px;"></i>
                </template>
                <el-option
                  v-for="item in secretList"
                  :key="item.metadata.name"
                  :value="item.metadata.name"
                  :label="item.metadata.name"
                ></el-option>
              </el-select>

              <el-button icon="el-icon-refresh" style="margin-left: 4px;" @click="getSecretList"></el-button>

              <div class="desc">
                若无目标密钥，您可以
                <a href="javascript:;" @click="secretDialogVisible = true">[立即创建]</a>。
                密钥会创建在您选择的集群和命名空间下， (当前选择的集群:{{ cluster }} / 命名空间:{{
                  form.metadata.namespace
                }})
              </div>
            </div>

            <span class="desc" v-else>请选择集群和命名空间</span>
          </el-form-item>
        </el-tab-pane>
      </el-tabs>
    </el-form>

    <el-dialog
      title="创建密钥"
      :visible.sync="secretDialogVisible"
      top="30px"
      width="600px"
      @close="closeSecretDialog"
      :close-on-click-modal="false"
      append-to-body
    >
      <el-form size="small" label-position="top" :model="secretForm" ref="secretForm" :rules="secretRules">
        <el-form-item :label="$t('name')" prop="name">
          <el-input v-model.trim="secretForm.name"></el-input>
          <span class="help">{{ $t("tips.nameCommonPattern") }}</span>
        </el-form-item>

        <el-form-item :label="$t('host')" prop="server">
          <el-input v-model.trim="secretForm.server"></el-input>
          <span class="help">请勿以http或https开头，直接填写域名或IP即可</span>
        </el-form-item>

        <el-form-item :label="$t('userName')" prop="userName">
          <el-input v-model.trim="secretForm.userName"></el-input>
        </el-form-item>

        <el-form-item :label="$t('password')" prop="password">
          <el-input v-model.trim="secretForm.password"></el-input>
        </el-form-item>

        <el-form-item :label="$t('email')">
          <el-input v-model.trim="secretForm.email"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button type="primary" @click="createSecret" size="small" :loading="createSecretLoading">
          {{ $t("handle.submit") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { storageClassListByLocation } from "api/storageClass";
import { secretListByLocation, createImagePullSecrets } from "api/secret";

export default {
  props: {
    form: {
      type: Object
    },
    update: {
      type: Boolean,
      default: false
    },
    vendor: {
      type: String
    },
    region: {
      type: String
    },
    cluster: {
      type: String
    }
  },

  data() {
    return {
      rules: {
        "metadata.name": [
          { required: true, message: this.$t("rules.require"), trigger: "blur" },
          {
            pattern: /^[a-z0-9]([-a-z0-9]*[a-z0-9])?(\.[a-z0-9]([-a-z0-9]*[a-z0-9])?)*$/,
            message: this.$t("rules.pattern"),
            trigger: "blur"
          }
        ],

        "metadata.namespace": { required: true, message: this.$t("rules.require"), trigger: "change" },
        "spec.cronjob.spec.schedule": { required: true, message: this.$t("rules.require"), trigger: "blur" }
      },

      secretRules: {
        name: [
          { required: true, message: this.$t("rules.require"), trigger: "blur" },
          {
            pattern: /^[a-z0-9]([-a-z0-9]*[a-z0-9])?(\.[a-z0-9]([-a-z0-9]*[a-z0-9])?)*$/,
            message: this.$t("rules.pattern"),
            trigger: "blur"
          }
        ],

        server: { required: true, message: this.$t("rules.require"), trigger: "blur" },
        userName: { required: true, message: this.$t("rules.require"), trigger: "blur" },
        password: { required: true, message: this.$t("rules.require"), trigger: "blur" },
        email: [{ type: "email", message: this.$t("rules.pattern"), trigger: ["blur", "change"] }]
      },

      storageClassLoading: false,
      storageClassOptions: [],
      secretList: [],
      secretListLoading: false,

      imagePullSecrets: [],
      secretDialogVisible: false,
      createSecretLoading: false,
      secretForm: {
        name: "",
        server: "",
        userName: "",
        password: "",
        email: ""
      }
    };
  },

  computed: {
    type() {
      return this.form.spec.type;
    }
  },

  watch: {
    "form.spec.deployment.spec.strategy.type": function(value) {
      if (this.form.spec.type === "Deployment") {
        this.$delete(this.form.spec.deployment.spec.strategy, "rollingUpdate");
        if (value === "RollingUpdate") {
          this.$set(this.form.spec.deployment.spec.strategy, "rollingUpdate", { maxUnavailable: 0, maxSurge: 3 });
        }
      }
    },

    "form.spec.statefulset.spec.updateStrategy.type": function(value) {
      if (this.form.spec.type === "StatefulSet") {
        this.$delete(this.form.spec.statefulset.spec.updateStrategy, "rollingUpdate");
        if (value === "RollingUpdate") {
          this.$set(this.form.spec.statefulset.spec.updateStrategy, "rollingUpdate", { partition: 0 });
        }
      }
    },

    "form.spec.daemonset.spec.updateStrategy.type": function(value) {
      if (this.form.spec.type === "DaemonSet") {
        this.$delete(this.form.spec.daemonset.spec.updateStrategy, "rollingUpdate");
        if (value === "RollingUpdate") {
          this.$set(this.form.spec.daemonset.spec.updateStrategy, "rollingUpdate", { maxUnavailable: 0 });
        }
      }
    },

    cluster(value) {
      if (value && this.vendor && this.region && this.form.metadata.namespace) {
        this.imagePullSecrets = [];
        this.getSecretList();
      }
    },

    "form.metadata.namespace": function(value) {
      if (value && this.vendor && this.region && this.cluster) {
        this.imagePullSecrets = [];
        this.getSecretList();
      }
    }
  },

  methods: {
    initStorageClass() {
      if (this.vendor && this.region && this.cluster) {
        this.storageClassLoading = true;
        storageClassListByLocation({ vendor: this.vendor, region: this.region, cluster: this.cluster }).then(
          response => {
            if (response.code === 0) {
              this.storageClassLoading = false;
              this.storageClassOptions = response.data.items;
            }
          }
        );
      }
    },

    addVolumeClaimTemplates() {
      this.form.spec.volumeClaimTemplates.push({
        metadata: {
          name: ""
        },
        rootPath: "",
        spec: {
          accessModes: ["ReadWriteOnce"],
          resources: {
            requests: {
              storage: "2Gi"
            }
          },
          storageClassName: "",
          volumeMode: "Filesystem"
        }
      });
    },

    initAffinity() {},

    getImagePullSecrets() {
      if (this.form.spec.type == "Deployment") {
        if (!this.form.spec.deployment.spec.template.spec.imagePullSecrets)
          this.$set(this.form.spec.deployment.spec.template.spec, "imagePullSecrets", []);

        return this.form.spec.deployment.spec.template.spec.imagePullSecrets;
      }

      if (this.form.spec.type == "StatefulSet") {
        if (!this.form.spec.statefulset.spec.template.spec.imagePullSecrets)
          this.$set(this.form.spec.statefulset.spec.template.spec, "imagePullSecrets", []);

        return this.form.spec.statefulset.spec.template.spec.imagePullSecrets;
      }

      if (this.form.spec.type == "CronJob") {
        if (!this.form.spec.cronjob.spec.jobTemplate.spec.template.spec.imagePullSecrets)
          this.$set(this.form.spec.cronjob.spec.jobTemplate.spec.template.spec, "imagePullSecrets", []);
        return this.form.spec.cronjob.spec.jobTemplate.spec.template.spec.imagePullSecrets;
      }

      if (this.form.spec.type == "DaemonSet") {
        if (!this.form.spec.daemonset.spec.template.spec.imagePullSecrets)
          this.$set(this.form.spec.daemonset.spec.template.spec, "imagePullSecrets", []);
        return this.form.spec.daemonset.spec.template.spec.imagePullSecrets;
      }
    },

    getTolerations() {
      if (this.form.spec.type == "Deployment") {
        if (!this.form.spec.deployment.spec.template.spec.tolerations)
          this.$set(this.form.spec.deployment.spec.template.spec, "tolerations", []);

        return this.form.spec.deployment.spec.template.spec.tolerations;
      }

      if (this.form.spec.type == "StatefulSet") {
        if (!this.form.spec.statefulset.spec.template.spec.tolerations)
          this.$set(this.form.spec.statefulset.spec.template.spec, "tolerations", []);

        return this.form.spec.statefulset.spec.template.spec.tolerations;
      }

      if (this.form.spec.type == "CronJob") {
        if (!this.form.spec.cronjob.spec.jobTemplate.spec.template.spec.tolerations)
          this.$set(this.form.spec.cronjob.spec.jobTemplate.spec.template.spec, "tolerations", []);
        return this.form.spec.cronjob.spec.jobTemplate.spec.template.spec.tolerations;
      }

      if (this.form.spec.type == "DaemonSet") {
        if (!this.form.spec.daemonset.spec.template.spec.tolerations)
          this.$set(this.form.spec.daemonset.spec.template.spec, "tolerations", []);
        return this.form.spec.daemonset.spec.template.spec.tolerations;
      }
    },

    getAffinity() {
      let nodeAffinity = {
        requiredDuringSchedulingIgnoredDuringExecution: {
          nodeSelectorTerms: [
            {
              matchExpressions: []
            }
          ]
        }
      };

      let podAffinity = {
        requiredDuringSchedulingIgnoredDuringExecution: [
          {
            labelSelector: {
              matchExpressions: []
            },
            namespaces: [],
            topologyKey: ""
          }
        ]
      };

      let podAntiAffinity = {
        requiredDuringSchedulingIgnoredDuringExecution: [
          {
            labelSelector: {
              matchExpressions: []
            },
            namespaces: [],
            topologyKey: ""
          }
        ]
      };

      let affinity = {
        nodeAffinity,
        podAffinity,
        podAntiAffinity
      };

      let specData = null;

      switch (this.form.spec.type) {
        case "Deployment":
          specData = this.form.spec.deployment.spec.template.spec;
          break;

        case "StatefulSet":
          specData = this.form.spec.statefulset.spec.template.spec;
          break;

        case "CronJob":
          specData = this.form.spec.cronjob.spec.jobTemplate.spec.template.spec;
          break;

        case "DaemonSet":
          specData = this.form.spec.daemonset.spec.template.spec;
          break;
      }

      if (!specData.affinity) {
        this.$set(specData, "affinity", affinity);
      } else {
        if (!specData.affinity.nodeAffinity) {
          this.$set(specData.affinity, "nodeAffinity", nodeAffinity);
        } else {
          if (!specData.affinity.nodeAffinity.requiredDuringSchedulingIgnoredDuringExecution)
            this.$set(specData.affinity.nodeAffinity, "requiredDuringSchedulingIgnoredDuringExecution", {
              nodeSelectorTerms: [
                {
                  matchExpressions: []
                }
              ]
            });
        }

        if (!specData.affinity.podAffinity) {
          this.$set(specData.affinity, "podAffinity", podAffinity);
        } else {
          if (specData.affinity.podAffinity.requiredDuringSchedulingIgnoredDuringExecution) {
            if (!specData.affinity.podAffinity.requiredDuringSchedulingIgnoredDuringExecution[0].namespaces)
              this.$set(
                specData.affinity.podAffinity.requiredDuringSchedulingIgnoredDuringExecution[0],
                "namespaces",
                []
              );
          } else {
            this.$set(specData.affinity.podAffinity, "requiredDuringSchedulingIgnoredDuringExecution", [
              {
                labelSelector: {
                  matchExpressions: []
                },
                namespaces: [],
                topologyKey: ""
              }
            ]);
          }
        }

        if (!specData.affinity.podAntiAffinity) {
          this.$set(specData.affinity, "podAntiAffinity", podAntiAffinity);
        } else {
          if (specData.affinity.podAntiAffinity.requiredDuringSchedulingIgnoredDuringExecution) {
            if (!specData.affinity.podAntiAffinity.requiredDuringSchedulingIgnoredDuringExecution[0].namespaces)
              this.$set(
                specData.affinity.podAntiAffinity.requiredDuringSchedulingIgnoredDuringExecution[0],
                "namespaces",
                []
              );
          } else {
            this.$set(specData.affinity.podAntiAffinity, "requiredDuringSchedulingIgnoredDuringExecution", [
              {
                labelSelector: {
                  matchExpressions: []
                },
                namespaces: [],
                topologyKey: ""
              }
            ]);
          }
        }
      }

      return specData.affinity;
    },

    getHostAliases() {
      if (this.form.spec.type == "Deployment") {
        if (!this.form.spec.deployment.spec.template.spec.hostAliases)
          this.$set(this.form.spec.deployment.spec.template.spec, "hostAliases", []);
        return this.form.spec.deployment.spec.template.spec.hostAliases;
      }

      if (this.form.spec.type == "StatefulSet") {
        if (!this.form.spec.statefulset.spec.template.spec.hostAliases)
          this.$set(this.form.spec.statefulset.spec.template.spec, "hostAliases", []);
        return this.form.spec.statefulset.spec.template.spec.hostAliases;
      }

      if (this.form.spec.type == "CronJob") {
        if (!this.form.spec.cronjob.spec.jobTemplate.spec.template.spec.hostAliases)
          this.$set(this.form.spec.cronjob.spec.jobTemplate.spec.template.spec, "hostAliases", []);
        return this.form.spec.cronjob.spec.jobTemplate.spec.template.spec.hostAliases;
      }

      if (this.form.spec.type == "DaemonSet") {
        if (!this.form.spec.daemonset.spec.template.spec.hostAliases)
          this.$set(this.form.spec.daemonset.spec.template.spec, "hostAliases", []);
        return this.form.spec.daemonset.spec.template.spec.hostAliases;
      }
    },

    getSecretList() {
      this.secretListLoading = true;

      secretListByLocation({
        vendor: this.vendor,
        region: this.region,
        cluster: this.cluster,
        namespace: this.form.metadata.namespace
      }).then(response => {
        this.secretListLoading = false;
        if (response.code === 0) {
          this.secretList = response.data.items;
        }
      });
    },

    showInput(item, index) {
      this.$set(item, "inputVisible", true);

      this.$nextTick(() => {
        this.$refs[`alias.${index}`][0].$refs.input.focus();
      });
    },

    handleInputConfirm(item) {
      if (item.inputValue) item.hostnames.push(item.inputValue);
      this.$set(item, "inputVisible", false);
      this.$set(item, "inputValue", "");
    },

    closeSecretDialog() {
      this.$refs["secretForm"].resetFields();
      this.secretForm = {
        name: "",
        server: "",
        userName: "",
        password: "",
        email: ""
      };
    },

    createSecret() {
      this.$refs["secretForm"].validate(valid => {
        if (valid) {
          this.createSecretLoading = true;

          createImagePullSecrets(this.secretForm, this.form.metadata.namespace, {
            vendor: this.vendor,
            region: this.region,
            cluster: this.cluster
          }).then(response => {
            this.createSecretLoading = false;
            if (response.code === 0) {
              this.getSecretList();
              this.secretDialogVisible = false;
              this.imagePullSecrets.push(this.secretForm.name);
            }
          });
        }
      });
    }
  },

  mounted() {
    if (this.form.metadata.namespace && this.vendor && this.region && this.cluster) this.getSecretList();

    this.imagePullSecrets = this.getImagePullSecrets().map(item => {
      return item.name;
    });
  }
};
</script>

<style lang="scss" scoped>
.el-tag {
  margin-right: 10px;
  margin-bottom: 10px;
}
</style>
