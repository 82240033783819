<template>
  <div v-loading="loading">
    <!-- 为了兼容$refs["configMapForm"].$refs["form"].validate方法 -->

    <el-button
      type="primary"
      size="small"
      style="margin-bottom: 10px;"
      plain
      v-if="configMapList.length > 0"
      @click="form.configs.push({ name: '', overlay: false, items: [] })"
    >
      {{ $t("handle.add") }}
    </el-button>

    <div v-else class="help">
      {{ $t("emptyInLocation", [`Namespcae: ${namespace}`]) }}
    </div>

    <el-form :model="form" ref="form" label-position="top" size="small">
      <el-card v-for="(config, index) in form.configs" :key="index" shadow="never" style="margin-bottom: 10px;">
        <div slot="header" class="clearfix">
          <span>
            {{ type == "secret" ? $t("Secret") : $t("ConfigMap") }}
            {{ index + 1 }}
          </span>
          <el-button
            style="float: right; padding: 3px 0"
            type="text"
            size="small"
            @click="form.configs.splice(index, 1)"
          >
            {{ $t("handle.delete") }}
          </el-button>
        </div>

        <el-form-item
          :label="type == 'secret' ? $t('Secret') : $t('ConfigMap')"
          :prop="'configs.' + index + '.name'"
          :rules="{ required: true, message: $t('rules.require'), trigger: 'change' }"
        >
          <el-select style="width: 100%;" v-model="config.name" filterable @change="confMapSelectChange">
            <el-option
              v-for="item in configMapList"
              :key="item.metadata.name"
              :value="item.metadata.name"
              :label="item.metadata.name"
              :disabled="includes(existedConfNames, item.metadata.name)"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item :label="$t('overlay')" v-if="config.name">
          <el-switch v-model="config.overlay"></el-switch>
          <div class="help">
            {{ $t("configMapTips.overlay") }}
          </div>
        </el-form-item>

        <el-form-item v-if="config.name" :prop="'configs.' + index + '.items'">
          <div class="help" v-if="isEmptyConfItem(config.name)">
            <i class="el-icon-warning"></i>
            {{ $t("emptyResource", [$t("configMapItem")]) }}
          </div>

          <div v-else>
            <div class="config-item" v-for="(item, index) in config.items" :key="item.key">
              <svg-icon icon-class="ConfigMap"></svg-icon>
              <div class="content">
                <div class="title">
                  {{ item.key }}
                </div>
                <div class="desc">
                  {{ $t("configMapItem") }}
                </div>
              </div>

              <div class="content">
                <div class="title">
                  {{ item.path ? item.path : "-" }}
                </div>
                <div class="desc">
                  {{ $t("mountPath") }}
                </div>
              </div>

              <div>
                <el-tooltip effect="dark" :content="$t('handle.edit')" placement="bottom">
                  <svg-icon
                    icon-class="edit"
                    class-name="handle-icon"
                    @click.native="editItem(item, config)"
                  ></svg-icon>
                </el-tooltip>

                <el-tooltip effect="dark" :content="$t('handle.delete')" placement="bottom">
                  <svg-icon
                    icon-class="delete"
                    class-name="handle-icon"
                    @click.native="config.items.splice(index, 1)"
                  ></svg-icon>
                </el-tooltip>
              </div>
            </div>

            <div
              class="config-item"
              @click="addItem(config)"
              v-if="config.items.length < mapConfDataLength(config.name)"
            >
              <svg-icon icon-class="add"></svg-icon>
              <div class="content">
                <div class="title">{{ $t("handle.add") }}</div>
                <div class="desc">{{ $t("handle.configMapAddItem") }}</div>
              </div>
            </div>
          </div>
        </el-form-item>
      </el-card>
    </el-form>

    <el-dialog :title="$t('configMapItem')" :visible.sync="dialogVisible" :append-to-body="true" width="800px">
      <div class="item-container">
        <el-form :model="itemForm" size="small" label-position="top">
          <el-form-item :label="$t('configMapItem')">
            <el-select v-model="itemForm.key" size="small" style="width: 100%;" :disabled="formType === 'edit'">
              <el-option
                v-for="item in mapItemList()"
                :key="item.name"
                :value="item.name"
                :label="item.name"
                :disabled="item.selected"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item :label="$t('mountPath')">
            <el-input v-model.trim="itemForm.path"></el-input>
            <div class="help">
              {{ $t("configMapTips.mountPath") }}
            </div>
          </el-form-item>

          <el-form-item :label="$t('mode')">
            <el-select v-model="itemForm.mode">
              <el-option value="ReadOnly"></el-option>
              <el-option value="ReadWrite"></el-option>
            </el-select>

            <div class="help">
              {{ $t("configMapTips.mode") }}
            </div>
          </el-form-item>
        </el-form>
        <editor
          :value="mapConfValue(itemForm.key)"
          @init="editorInit"
          lang="yaml"
          theme="cobalt"
          width="100%"
          height="400"
          class="editor"
        >
        </editor>
      </div>

      <span slot="footer">
        <el-button type="primary" size="small" @click="itemSubmit">
          {{ $t("handle.submit") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { cloneDeep, includes, isEmpty } from "lodash";
import { configMapListByLocation } from "api/configmap";
import { secretListByLocation } from "api/secret";
export default {
  props: {
    namespace: {
      type: String
    },

    type: {
      type: String
    },

    configs: {
      type: Array,
      default() {
        return [];
      }
    },

    locationList: {
      type: Array
    },

    disabledNames: {
      type: Array,
      default() {
        return [];
      }
    }
  },

  components: {
    editor: require("vue2-ace-editor")
  },

  data() {
    return {
      form: {
        configs: []
      },
      configMapList: [],
      loading: false,
      dialogVisible: false,
      itemForm: {},

      currentConf: {},
      formType: ""
    };
  },

  computed: {
    existedConfNames() {
      return [...this.form.configs.map(item => item.name), ...this.disabledNames];
    }
  },

  mounted() {
    this.getConfigMapList();
    this.form.configs = cloneDeep(this.configs);
  },

  methods: {
    includes,

    editorInit(editor) {
      editor.setReadOnly(true);
      require("brace/ext/searchbox");
      require("brace/mode/yaml");
      require("brace/theme/cobalt");
    },

    isEmptyConfItem(name) {
      if (name) {
        for (let item of this.configMapList) {
          if (item.metadata.name === name) return isEmpty(item.data);
        }
      }
    },

    mapConfValue(key) {
      if (key) {
        for (let item of this.configMapList) {
          if (item.metadata.name === this.currentConf.name) {
            return item.data[key];
          }
        }
      } else {
        return "";
      }
    },

    mapConfDataLength(confName) {
      for (let item of this.configMapList) {
        if (item.metadata.name === confName) {
          if (item.data && Object.keys(item.data)) return Object.keys(item.data).length;
        }
      }
    },

    mapItemList() {
      for (let item of this.configMapList) {
        if (item.metadata.name === this.currentConf.name) {
          // Object.keys(item.data)全部配置项
          // config.path为已用配置
          // return [{name, disabled}]
          let selectList = [];
          Object.keys(item.data).forEach(dataItem => {
            includes(
              this.currentConf.items.map(item => item.key),
              dataItem
            )
              ? selectList.push({ name: dataItem, selected: true })
              : selectList.push({ name: dataItem, selected: false });
          });
          return selectList;
        }
      }
    },

    confMapSelectChange(val) {
      // pathData { key1:val1, key2:val2 }

      let pathData = {};

      for (let item of this.configMapList) {
        if (item.metadata.name == val) {
          pathData = item.data;
          break;
        }
      }

      for (let item of this.form.configs) {
        if (item.name == val) {
          this.$set(item, "items", []);
          for (let key in pathData) {
            item.items.push({ key, path: "", mode: "ReadWrite" });
          }
          break;
        }
      }
    },

    getConfigMapList() {
      this.loading = true;
      if (this.namespace) {
        let action = null;
        this.type == "secret" ? (action = secretListByLocation) : (action = configMapListByLocation);

        action({
          vendor: this.locationList[0],
          region: this.locationList[1],
          cluster: this.locationList[2],
          namespace: this.namespace
        }).then(response => {
          this.loading = false;
          if (response.code === 0) {
            this.configMapList = response.data.items;
          }
        });
      }
    },

    editItem(item, currentConf) {
      this.currentConf = cloneDeep(currentConf);
      this.formType = "edit";
      this.itemForm = cloneDeep(item);
      this.dialogVisible = true;
    },

    addItem(currentConf) {
      this.currentConf = cloneDeep(currentConf);
      this.formType = "add";
      this.itemForm = { key: "", path: "", mode: "ReadWrite" };
      this.dialogVisible = true;
    },

    itemSubmit() {
      if (this.formType == "edit") {
        this.form.configs.forEach(config => {
          if (config.name == this.currentConf.name) {
            config.items.forEach((item, index, arr) => {
              if (this.itemForm.key === item.key) {
                arr.splice(index, 1, this.itemForm);
              }
            });
          }
        });
      }

      if (this.formType == "add") {
        this.form.configs.forEach(config => {
          if (config.name == this.currentConf.name) {
            config.items.push(this.itemForm);
          }
        });
      }

      this.dialogVisible = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";

.config-item {
  @include resource-card();
  line-height: 1 !important;
}

.item-container {
  @include flex(space-between, flex-start);

  .el-form {
    flex: 1;
    padding: 20px;
    margin-right: 10px;
  }

  .editor {
    flex-basis: 400px;
  }
}
</style>
