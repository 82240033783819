<template>
  <div>
    <div v-if="'exec' in data">
      <el-row :gutter="10" v-for="(item, index) in data.exec.command" :key="index" type="flex" align="bottom">
        <el-col :span="12">
          <el-form-item :label="$t('command') + (index + 1)">
            <el-input v-model="data.exec.command[index]"> </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item>
            <el-button size="small" @click="data.exec.command.splice(index, 1)">{{ $t("handle.delete") }}</el-button>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item>
        <el-button size="small" @click="data.exec.command.push('')">+ 添加执行命令</el-button>
      </el-form-item>
    </div>

    <el-form-item :label="$t('path')" v-if="'httpGet' in data">
      <el-input v-model.trim="data.httpGet.path"></el-input>
    </el-form-item>

    <el-form-item :label="$t('port')" v-if="'httpGet' in data">
      <el-input v-model.number="data.httpGet.port" :min="0" oninput="value=value.replace(/[^0-9.]/g,'')"></el-input>
    </el-form-item>

    <el-form-item :label="$t('port')" v-if="'tcpSocket' in data">
      <el-input v-model.number="data.tcpSocket.port" :min="0" oninput="value=value.replace(/[^0-9.]/g,'')"></el-input>
    </el-form-item>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object
    }
  }
};
</script>
