<template>
  <el-radio-group v-model="checked" @change="change">
    <el-radio-button label="exec">{{ $t("exec") }}</el-radio-button>
    <el-radio-button label="httpGet">{{ $t("httpRequest") }}</el-radio-button>
    <el-radio-button label="tcpSocket">{{ $t("tcp") }}</el-radio-button>
  </el-radio-group>
</template>

<script>
export default {
  props: {
    data: {
      type: Object
    }
  },

  data() {
    return {
      checked: ""
    };
  },

  mounted() {
    if ("exec" in this.data) this.checked = "exec";
    if ("httpGet" in this.data) this.checked = "httpGet";
    if ("tcpSocket" in this.data) this.checked = "tcpSocket";
  },

  methods: {
    change(val) {
      let data;

      switch (val) {
        case "exec":
          data = {
            command: []
          };
          break;

        case "httpGet":
          data = {
            path: "",
            port: 8080,
            scheme: "HTTP"
          };
          break;

        case "tcpSocket":
          data = {
            port: 8080
          };
          break;
      }

      let clone = JSON.parse(JSON.stringify(this.data));
      delete clone.exec;
      delete clone.httpGet;
      delete clone.tcpSocket;

      clone[val] = data;
      this.$emit("update:data", clone);
    }
  }
};
</script>
